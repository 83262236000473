<template >
      <router-view/>
</template>
<script>
export default {
  name: "App",
}
</script>

<style lang="scss">
@import "./scss/variables.scss";
#app {
  font-family: $body-font-family !important;
}
</style>